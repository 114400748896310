// src/pages/PrivacyPolicy.tsx
import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div>
      <h1>Política de Privacidad</h1>
      <p>Detalles sobre cómo manejamos tu privacidad.</p>
    </div>
  );
};

export default PrivacyPolicy;
