// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Register from './pages/Register';
import Home from './pages/Home';
import Pricing from './pages/Pricing';
import AboutUs from './pages/AboutUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import DataPolicy from './pages/DataPolicy';
import './App.css';
import Edu from './pages/UseCaseEducation'; 

const App: React.FC = () => {
  return (
    <div className="app-background">
      <Router>
        <Navbar />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/education" element={<Edu />} />
            <Route path="/register" element={<Register />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/data-policy" element={<DataPolicy />} />
          </Routes>
        </main>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
