import React from 'react';
import PricingCard from '../components/PricingCard';
import './Pricing.css';

const Pricing: React.FC = () => {
  return (
    <div className="pricing-page">
      {/* Título */}
      <div className="pricing-header">
        <h2>
        Resultados comparables a contar con un equipo completo de 15 comerciales.
        </h2>
      </div>

      {/* Contenedor de las tarjetas */}
      <div className="pricing-cards-container">
        <PricingCard
          title="BASIC - 30 días de Prueba Gratuita"
          price="250 €"
          monthlyPrice="o 299€ mensual - 3.888€ anual"
          features={["1 usuario", "1 Agente", "100 Conversaciones", "CRM Integrado", "3 Campañas"]}
          buttonText="Comienza gratis"
          gradient="linear-gradient(to bottom, #9E80B4, #8BB5DA)"
        />
        <PricingCard
          title=""
          price="590 €"
          monthlyPrice="o 750€ mensual - 9.000€ anual"
          features={["3 usuarios (+80€ por usuario)", "5 Agentes", "Conversaciones Ilimitadas", "Campañas Ilimitadas", "Integración con CRM de terceros"]}
          buttonText="Obtener Ilimitado"
          gradient="linear-gradient(to bottom, #8CB5A5, #6DB192)"
          tag="Mejor Opción para PYMES"
        />
        <PricingCard
          title="Empresarial"
          price="Personalizado"
          monthlyPrice=""
          features={["Todo en Ilimitado +", "Exportación Masiva", "Analítica Avanzada", "Seguridad Avanzada"]}
          buttonText="Contacta para un plan a medida"
          gradient="linear-gradient(to bottom, #D98C82, #E4A76A)"
        />
      </div>
    </div>
  );
};

export default Pricing;
