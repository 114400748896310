import React from 'react';
import './AboutUs.css';
import team1 from '../images/team1.png';
import team2 from '../images/team2.png';
import team3 from '../images/team3.png';
import linkedin from '../images/linkedin.png';


const AboutUs: React.FC = () => {
  return (
    <div className="about-us-page">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>Acerca de Nosotros</h1>
          <p>
            Converflow es una solución SaaS diseñada para revolucionar los procesos comerciales 
            y de marketing en las empresas. Ofrecemos herramientas inteligentes que automatizan 
            conversaciones y maximizan la eficiencia de los equipos.
          </p>
        </div>
        
      </section>

      {/* Founding Team Section */}
      <section className="team-section">
        <h2>El Equipo Fundador</h2>
        <div className="team-grid">
        <div className="team-member">
  <img src={team1} alt="Jose Luis Castedo - CEO" />
  <h3>Jose Luis Castedo</h3>
  <p className="team-description">
    Apasionado por el emprendimiento, la tecnología y la educación.
  </p>
  <p className="team-position"><strong>Desarrollo de negocio</strong></p>
  <a href="https://www.linkedin.com/in/joseluiscastedo" target="_blank" rel="noopener noreferrer">
    <img className="linkedin-image" src={linkedin} alt="LinkedIn" />
  </a>
</div>
          <div className="team-member">
            <img src={team2} alt="Luis Escobar - CMO" />
            <h3>Luis Escobar</h3>
            <p className="team-description">
              Experto en estrategias de marketing digital y crecimiento empresarial.
            </p>
            <p className="team-position"><strong>Crecimiento y Marketing </strong></p>
            <a
              href="https://www.linkedin.com/in/luis-escobar-0b8b3414b/"
              target="_blank"
            >
             <img className="linkedin-image" src={linkedin} />
            </a>
          </div>
          <div className="team-member">
            <img src={team3} alt="Leifer Mendez - CTO" />
            <h3>Leifer Mendez</h3>
            <p className="team-description">
              Experto en inteligencia artificial y automatización de procesos comerciales.
            </p>
            <p className="team-position"><strong>Ingeniero de Software</strong></p>
            <a
              href="https://www.linkedin.com/in/leifermendez"
              target="_blank"
              rel="noopener noreferrer"
              className="linkedin-icon"
            >
              <img className="linkedin-image" src={linkedin}/>
            </a>
          </div>
        </div>
      </section>

      {/* Our Story Section */}
      <section className="story-section">
        <h2>La Historia de Converflow</h2>
        <p>
          Converflow nació al identificar un reto crítico en los procesos comerciales del sector educativo: 
          la falta de herramientas efectivas para gestionar y automatizar las interacciones con clientes potenciales. 
          Nuestro equipo combinó su experiencia en tecnología, marketing y educación para crear la solución perfecta 
          que no solo mejora la productividad, sino que también optimiza cada punto de contacto con los clientes.
        </p>
        <p>
          Hoy, Converflow es una herramienta que impulsa la transformación digital en empresas de todo tipo, 
          ayudándolas a escalar sus operaciones y a generar relaciones más significativas con sus clientes.
        </p>
      </section>
    </div>
  );
};

export default AboutUs;
