import React from 'react';
import eit from '../assets/eit.png';
import eit2 from '../assets/eit2.png';
import logo3 from '../assets/logocso.png';


const Partners: React.FC = () => {
  return (
    <div style={{ backgroundColor: 'white', width: '100vw', padding: '40px 0', textAlign: 'center' }}>
      <h2 style={{ color: '#9E80B4', marginBottom: '20px' }}>Nuestros Partners</h2>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', alignItems: 'center', flexWrap: 'wrap' }}>
      <img 
          src={logo3} 
          alt="Partner Logo 3" 
          style={{ maxWidth: '80px', height: 'auto', objectFit: 'contain', marginRight:'75px' }} 
        />
       <img 
          src={eit2} 
          alt="Partner EIT 2" 
          style={{ maxWidth: '350px', height: 'auto', objectFit: 'contain' }} 
        />
        <img 
          src={eit} 
          alt="Partner EIT" 
          style={{ maxWidth: '120px', height: 'auto', objectFit: 'contain',marginRight:'55px' }} 
        />
        
        
        
      </div>
    </div>
  );
};

export default Partners;
