// src/components/FeatureBox.tsx
import React from 'react';
import './FeatureBox.css';
import sampleImage from '../assets/sample-image.png'; // Cambia esto a la ruta de tu imagen

const FeatureBox: React.FC = () => {
  return (
    <div className="feature-box">
      <div className="feature-content">
        <span className="feature-tag">#innovation4education</span>
        <h2>Optimiza procesos Comerciales <br />de tu Centro Educativo</h2>
        <p>
          Aumenta la eficiencia de tu equipo y mejora la experiencia de tus clientes 
          con nuestras soluciones automatizadas y fáciles de usar.
        </p>
        {/* Añadimos las características aquí */}
        <ul className="feature-list">
          <li>📈 Automatización de campañas y monitorización en tiempo real.</li>
          <li>🤖 Agentes personalizables para respuestas 24/7 con IA privada.</li>
          <li>📊 Integración con CRM para un seguimiento centralizado.</li>
          <li>🔍 Análisis avanzado de conversaciones para insights valiosos.</li>
        </ul>
        <a href="https://converflow.ai/register" target="_blank" rel="noopener noreferrer" className="feature-button">
  Comienza de forma gratuita →
</a>

      </div>
      <div className="feature-image">
        <img src={sampleImage} alt="Flexible Solutions" />
      </div>
    </div>
  );
};

export default FeatureBox;

