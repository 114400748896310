// src/pages/Home.tsx
import React from 'react';
import FeatureBox from '../components/FeatureBox';
import SupportFeatures from '../components/SupportFeatures';
import './Home.css';
import CommentsEdu from '../components/CommentsEdu';

const Edu: React.FC = () => {
  return (
    <div className="home">
      <FeatureBox />
      <SupportFeatures />
 <h2>Comentarios de Clientes</h2>
      <CommentsEdu />
    </div>
  );
};

export default Edu;
