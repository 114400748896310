// src/components/CommentsEdu.tsx
import React, { useState, useEffect, useRef } from 'react';
import './CommentsEdu.css';

interface Comment {
  id: number;
  name: string;
  comment: string;
  position: string;
}

const comments: Comment[] = [
  {
    id: 1,
    name: "Blanca Conradi",
    comment: "Un 10. Atención a clientes 24/7, en tiempo real y con la opción a monitorear... mayor satisfacción de clientes y más ventas.",
    position: "CEO, FocusOn Education"
  },
  {
    id: 2,
    name: "Alex Havard",
    comment: "Hemos automatizado el proceso de venta digital gracias a la herramienta, muy contento.",
    position: "Founder Virtuous Leadership Institute"
  },
  {
    id: 3,
    name: "María Diaz Gridilla",
    comment: "Sois unos cracks en Marketing Digital. ¡Enhorabuena!",
    position: "Directora de Marketing, Universidad Francisco de Vitoria"
  }
];

const CommentsEdu: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const carouselRef = useRef<HTMLDivElement>(null);

  // Cambiar comentario automáticamente cada 4 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isDragging) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % comments.length);
      }
    }, 4000);
    return () => clearInterval(interval);
  }, [isDragging]);

  // Manejar el inicio del arrastre
  const handleDragStart = (e: React.MouseEvent | React.TouchEvent) => {
    setIsDragging(true);
    setStartX(e.type === 'touchstart' ? (e as React.TouchEvent).touches[0].clientX : (e as React.MouseEvent).clientX);
  };

  // Manejar el movimiento del arrastre
  const handleDragMove = (e: React.MouseEvent | React.TouchEvent) => {
    if (!isDragging) return;

    const currentX = e.type === 'touchmove' ? (e as React.TouchEvent).touches[0].clientX : (e as React.MouseEvent).clientX;
    const deltaX = currentX - startX;

    if (deltaX > 50) {
      // Arrastre a la izquierda
      setCurrentIndex((prevIndex) => (prevIndex - 1 + comments.length) % comments.length);
      setIsDragging(false);
    } else if (deltaX < -50) {
      // Arrastre a la derecha
      setCurrentIndex((prevIndex) => (prevIndex + 1) % comments.length);
      setIsDragging(false);
    }
  };

  // Manejar el fin del arrastre
  const handleDragEnd = () => {
    setIsDragging(false);
  };

  return (

    <div><h2 className="support-title">¿Qué dicen nuestro clientes?</h2>
    
    <div
      className="comments-container"
      ref={carouselRef}
      onMouseDown={handleDragStart}
      onMouseMove={handleDragMove}
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}
      onTouchStart={handleDragStart}
      onTouchMove={handleDragMove}
      onTouchEnd={handleDragEnd}
    >
      <div
        className="comments-wrapper"
        style={{
          transform: `translateX(-${currentIndex * 100}%)`,
          transition: isDragging ? 'none' : 'transform 0.5s ease'
        }}
      >
        {comments.map((comment) => (
          <div className="comment-card" key={comment.id}>
            <p className="comment-text">"{comment.comment}"</p>
            <h4 className="comment-name">{comment.name}</h4>
            <p className="comment-position">{comment.position}</p>
          </div>
        ))}
      </div></div>
    </div>
  );
};

export default CommentsEdu;

