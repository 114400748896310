import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../assets/logo.png';

const Navbar: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      {/* Menú de escritorio existente */}
      <nav className="navbar">
        <div className="navbar-logo">
          <Link to="/"><img src={logo} alt="Logo" /></Link>
        </div>
        <ul className="navbar-menu">
          <li><Link to="/pricing">Precios</Link></li>
          <li><Link to="/about">Nosotros</Link></li>
        </ul>
        <div className="navbar-login">
          <a href="https://converflow.ai/register">
            <button className="register-button">Registrarme</button>
          </a>
          <a href="https://app.converflow.ai/">
            <button>Log In</button>
          </a>
        </div>
      </nav>

      {/* Menú móvil */}
      <nav className="mobile-navbar">
        <div className="mobile-navbar-header">
          <div className="mobile-navbar-logo">
            <Link to="/"><img src={logo} alt="Logo" /></Link>
          </div>
          <div
            className="mobile-navbar-hamburger"
            onClick={toggleMobileMenu}
          >
            ☰ {/* Símbolo de hamburguesa */}
          </div>
        </div>
        {isMobileMenuOpen && (
          <ul className="mobile-navbar-menu">
            <li><Link to="/pricing">Precios</Link></li>
            <li><Link to="/about">Nosotros</Link></li>
            <li><Link to="/register">Registrarse</Link></li>
            <li><Link to="/login">Iniciar Sesión</Link></li>
          </ul>
        )}
      </nav>
    </>
  );
};

export default Navbar;
