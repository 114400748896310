import React, { useState } from 'react';
import axios from 'axios';

// Tipo de mensaje
type Message = {
  text: string;
  sender: 'user' | 'bot';
};

const ChatApp: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState<string>('');

  // Prompt personalizado para OpenAI
  const systemMessage = {
    role: 'system',
    content:
      'Responde de forma breve, como si se tratara de un mensaje de whatsapp. Actúa como un agente comercial de Converflow, una herramienta que ayuda a automatizar procesos comerciales gracias a su sistema de agentes. Responde de manera profesional, clara y orientada a resolver las dudas del cliente, destacando los beneficios y características principales de Converflow. Una vez estén interesados y quieran contactar invitales a registrarse en https://www.converflow.ai/register ',
  };

  const handleSend = async () => {
    if (!input.trim()) return;

    // Agregar mensaje del usuario al estado
    const newMessages: Message[] = [...messages, { text: input, sender: 'user' }];
    setMessages(newMessages);
    setInput('');

    try {
      // Preparar mensajes para la API
      const apiMessages = [
        systemMessage, // Mensaje inicial del sistema
        ...newMessages.map((msg) => ({
          role: msg.sender === 'user' ? 'user' : 'assistant',
          content: msg.text,
        })),
      ];

      // Enviar solicitud a OpenAI
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4',
          messages: apiMessages,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer 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`, // Reemplaza con tu clave
          },
        }
      );

      // Agregar respuesta del bot al estado
      const botResponse = response.data.choices[0].message.content;
      setMessages((prev) => [...prev, { text: botResponse, sender: 'bot' }]);
    } catch (error) {
      console.error('Error fetching response:', error);
      setMessages((prev) => [
        ...prev,
        { text: 'Hubo un error, por favor intenta nuevamente.', sender: 'bot' },
      ]);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.chatWindow}>
        <div style={styles.messages}>
          {messages.map((message, index) => (
            <div
              key={index}
              style={{
                ...styles.message,
                alignSelf: message.sender === 'user' ? 'flex-end' : 'flex-start',
                backgroundColor: message.sender === 'user' ? '#DCF8C6' : '#FFF',
              }}
            >
              {message.text}
            </div>
          ))}
        </div>
        <div style={styles.inputContainer}>
          <input
            type="text"
            placeholder="Escribe un mensaje..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            style={styles.input}
          />
          <button onClick={handleSend} style={styles.button}>
            Enviar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatApp;

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // height: '100vh',
    backgroundColor: '',
  },
  chatWindow: {
    width: '400px',
    height: '500px',
    border: '0px solid #CCC',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    backgroundColor: '#fff',
  },
  messages: {
    flex: 1,
    padding: '10px',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  message: {
    padding: '10px',
    borderRadius: '10px',
    maxWidth: '70%',
    fontSize: '14px',
    boxShadow: '0px 1px 3px rgba(0,0,0,0.1)',
    color: 'black'
  },
  inputContainer: {
    display: 'flex',
    padding: '10px',
    color: 'black',
    backgroundColor: '#FFF',
  },
  input: {
    flex: 1,
    padding: '10px',
    borderRadius: '20px',
    border: '1px solid #CCC',
    marginRight: '10px',
    fontSize: '14px',
  },
  button: {
    padding: '10px 15px',
    borderRadius: '20px',
    border: 'none',
    backgroundColor: '#9E80B4',
    color: '#FFF',
    fontSize: '14px',
    cursor: 'pointer',
  },
};
