import React, { useState } from 'react';
import './Register.css';

const Register: React.FC = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    companyName: '',
    userCount: '',
    email: '',
    name: '',
    contactNumber: '',
    features: {
      automateConversations: false,
      smartCampaigns: false,
      leadQualification: false,
      other: '',
    },
  });
  const [error, setError] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    const isChecked = (e.target as HTMLInputElement).checked;

    if (type === 'checkbox') {
      setFormData({
        ...formData,
        features: {
          ...formData.features,
          [name]: isChecked,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    setError(''); // Limpiar el error cuando el usuario empieza a escribir
  };

  const isStepOneValid = formData.companyName && formData.userCount;
  const isStepTwoValid = formData.email && formData.name && formData.contactNumber;
  const isStepThreeValid =
    formData.features.automateConversations ||
    formData.features.smartCampaigns ||
    formData.features.leadQualification ||
    formData.features.other.trim() !== '';

  const handleNext = () => {
    if ((step === 1 && isStepOneValid) ||
        (step === 2 && isStepTwoValid) ||
        (step === 3 && isStepThreeValid)) {
      setStep(step + 1);
      setError(''); // Limpiar cualquier error al avanzar
    } else {
      setError('Por favor, completa todos los campos antes de continuar');
    }
  };

  const handleBack = () => setStep(step - 1);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    alert(`Te enviaremos las instrucciones de configuración a tu correo: ${formData.email}`);
  };

  const renderStepIndicator = () => (
    <div className="step-indicator">
      {Array.from({ length: 4 }, (_, index) => (
        <span key={index} className={`step-dot ${step === index + 1 ? 'active' : ''}`} />
      ))}
    </div>
  );

  return (
    <div className="register-form-container">
      <h2 className="register-form-title">Crea tu cuenta</h2>
      {renderStepIndicator()}
      <form className="register-form" onSubmit={handleSubmit}>
        {step === 1 && (
          <div className="register-step">
            <input
              className="register-input"
              type="text"
              name="companyName"
              placeholder="Nombre de la Empresa"
              value={formData.companyName}
              onChange={handleChange}
              required
            />
            <input
              className="register-input"
              type="number"
              name="userCount"
              placeholder="Número de Comerciales"
              value={formData.userCount}
              onChange={handleChange}
              required
            />
            <button
              className="register-button"
              type="button"
              onClick={handleNext}
            >
              Siguiente
            </button>
            {error && <p className="register-error">{error}</p>}
          </div>
        )}
        {step === 2 && (
          <div className="register-step">
            <input
              className="register-input"
              type="email"
              name="email"
              placeholder="Correo Electrónico"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              className="register-input"
              type="text"
              name="name"
              placeholder="Nombre Completo"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              className="register-input"
              type="text"
              name="contactNumber"
              placeholder="Número de Contacto"
              value={formData.contactNumber}
              onChange={handleChange}
              required
            />
            <div className="register-actions">
              <button className="register-button" type="button" onClick={handleBack}>
                Atrás
              </button>
              <button
                className="register-button"
                type="button"
                onClick={handleNext}
              >
                Siguiente
              </button>
              {error && <p className="register-error">{error}</p>}
            </div>
          </div>
        )}
        {step === 3 && (
          <div className="register-step">
            <label className="register-label">
              <input
                type="checkbox"
                name="automateConversations"
                checked={formData.features.automateConversations}
                onChange={handleChange}
              />
              Automatizar Conversaciones Comerciales
            </label>
            <label className="register-label">
              <input
                type="checkbox"
                name="smartCampaigns"
                checked={formData.features.smartCampaigns}
                onChange={handleChange}
              />
              Campañas Inteligentes
            </label>
            <label className="register-label">
              <input
                type="checkbox"
                name="leadQualification"
                checked={formData.features.leadQualification}
                onChange={handleChange}
              />
              Cualificación Automática de Form-Leads
            </label>
            <input
              className="register-input"
              type="text"
              name="other"
              placeholder="Otro (especificar)"
              value={formData.features.other}
              onChange={(e) => setFormData({
                ...formData,
                features: { ...formData.features, other: e.target.value }
              })}
            />
            <div className="register-actions">
              <button className="register-button" type="button" onClick={handleBack}>
                Atrás
              </button>
              <button
                className="register-button"
                type="button"
                onClick={handleNext}
                disabled={!isStepThreeValid}
              >
                Siguiente
              </button>
              {error && <p className="register-error">{error}</p>}
            </div>
          </div>
        )}
        {step === 4 && (
          <div className="register-step">
            <h3 className="register-form-summary-title">Resumen de la Información</h3>
            <ul className="register-summary-list">
              <li><strong>Empresa:</strong> {formData.companyName}</li>
              <li><strong>Número de Comerciales:</strong> {formData.userCount}</li>
              <li><strong>Correo:</strong> {formData.email}</li>
              <li><strong>Nombre:</strong> {formData.name}</li>
              <li><strong>Número de Contacto:</strong> {formData.contactNumber}</li>
              <li><strong>Intereses:</strong></li>
              {formData.features.automateConversations && (
                <li>Automatizar Conversaciones Comerciales</li>
              )}
              {formData.features.smartCampaigns && (
                <li>Campañas Inteligentes</li>
              )}
              {formData.features.leadQualification && (
                <li>Cualificación Automática de Form-Leads</li>
              )}
              {formData.features.other && (
                <li>Otro: {formData.features.other}</li>
              )}
            </ul>
            <p className="register-form-summary">
              Te enviaremos las instrucciones de configuración a tu correo: <strong>{formData.email}</strong>
            </p>
            <button className="register-button" type="button" onClick={handleBack}>
              Atrás
            </button>
            <button className="register-button" type="submit">
              Enviar
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default Register;
