// src/pages/Home.tsx
import React from 'react';
import FeatureBox from '../components/FeatureBox';
import SupportFeatures from '../components/SupportFeatures';
import Partners from '../components/Partners';
import CommentsEdu from '../components/CommentsEdu';
import './Home.css';

const Home: React.FC = () => {
  return (
    <div className="home">
      <FeatureBox />
      <SupportFeatures />
      <CommentsEdu />
      <Partners />
      
    </div>
  );
};

export default Home;
