// src/components/SupportFeatures.tsx
import React from 'react';
import './SupportFeatures.css';
import chatImage from '../assets/chat-image.png'; // Cambia esto a la imagen adecuada
import ChatApp from './ChatApp';

const SupportFeatures: React.FC = () => {
  return (
    <section className="support-features">
      <h2 className="support-title">Automatiza tus procesos de venta con IA</h2>
      <p>¿Te atreves a probarlo?</p>
      <div className="support-content">
        <div className="support-column">
          <div className="feature-item">
            <h3>1. Conocimiento</h3>
            <p>
              Converflow aprende todo sobre tu centro educativo, utilizando la información para 
              generar respuestas precisas y personalizadas para cada usuario.
            </p>
          </div>
          <div className="feature-item">
            <h3>3. Acciones</h3>
            <p>
              Personaliza la atención para cada estudiante, ajustando las respuestas y tomando 
              acciones de manera automática para satisfacer sus necesidades.
            </p>
          </div>
        </div>
        <div className="chat-simulation">
          <ChatApp />
        </div>
        <div className="support-column">
          <div className="feature-item">
            <h3>2. Comportamiento</h3>
            <p>
              Converflow es confiable, responde en el tono de tu institución y sigue tus políticas 
              para garantizar la mejor experiencia para los estudiantes.
            </p>
          </div>
          <div className="feature-item">
            <h3>4. Información</h3>
            <p>
              Insights generados por IA que te ofrecen visibilidad sobre todo el proceso de soporte, 
              asegurando la máxima calidad en la atención.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SupportFeatures;
