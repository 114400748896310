// src/components/Footer.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear(); // Obtiene el año actual

  return (
    <footer className="footer">
      <ul className="footer-menu">
        <li><Link to="/about">Sobre ConverFlow</Link></li>
        <li><Link to="/privacy-policy">Política de Privacidad</Link></li>
        <li><Link to="/contact">Contáctanos</Link></li>
      </ul>
      <p className="footer-rights">
        Todos los derechos reservados Converflow SL &reg; {currentYear}
      </p>
    </footer>
  );
};

export default Footer;
